import { Link, useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { setCookie } from "../components/functions";
import UserboardMenu from "../components/UserboardMenu";
import Form from "../components/form";
import Marketing from "../components/Marketing";

function Userboard() {
	const { settings, setLoading, setConfirm, setUser, user, reloadUser, lang, hasDelivery } =
		useContext(AppContext);
	const [addressesDelivery, setAddressesDelivery] = useState(false);
	const [addressesBilling, setAddressesBilling] = useState(false);
	const [newsletter, setNewsletter] = useState(user.newsletter == 1 ? true : false);
	const [marketing, setMarketing] = useState(user.marketing == 1 ? true : false);
	const navigate = useNavigate();

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);
		loadAddressesDelivery(); // eslint-disable-next-line
		loadAddressesBilling(); // eslint-disable-next-line
	}, []);

	const saveUser = async (request) => {
		setLoading(true);
		const response = await api.postProtected("/customers/edit/" + user.id + "/", request);
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setUser(response.data);
	};

	const deleteUser = async () => {
		setConfirm({
			title: <Label number={38} />,
			message: <Label number={50} />,
			onConfirm: () =>
				setConfirm({
					title: <Label number={38} />,
					message: <Label number={51} />,
					onConfirm: async () => {
						setLoading(true);
						const response = await api.getProtected(
							"/customers/delete/" + user.id + "/"
						);
						setLoading(false);
						if (response.success == 0)
							setConfirm({
								title: "Errore",
								message: response.error,
							});
						else {
							setUser(false);
							localStorage.removeItem("website-user");
							navigate("/login");
						}
					},
				}),
		});
	};

	const loadAddressesDelivery = async () => {
		const response = await api.postProtected("/addresses_delivery/list/", {
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setAddressesDelivery(response.rows);
	};

	const deleteAddressesDelivery = async (id) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.getProtected("/addresses_delivery/delete/" + id + "/");
				if (response.success == 0)
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				else loadAddressesDelivery();
			},
		});
	};

	const loadAddressesBilling = async () => {
		const response = await api.postProtected("/addresses_billing/list/", {
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setAddressesBilling(response.rows);
	};

	const deleteAddressesBilling = async (id) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.getProtected("/addresses_billing/delete/" + id + "/");
				if (response.success == 0)
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				else loadAddressesBilling();
			},
		});
	};

	const checkAffiliationCode = async (request) => {
		setLoading(true);
		request.lang = lang;
		const response = await api.postProtected("/customers/affiliate/" + user.id + "/", request);
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else reloadUser();
	};

	const cancelAffiliation = async () => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				saveUser({ company_id: null });
			},
		});
	};

	return (
		<div className="page">
			<div className="userboard">
				<UserboardMenu selected="userboard" />
				<div className="box">
					<h1>
						<Label number={42} />
						<div className="subtitle">{user.email}</div>
					</h1>
					<Form
						onSubmit={saveUser}
						fields={[
							{
								type: "text",
								name: "name",
								label: <Label number={54} />,
								value: user.name,
								required: true,
							},
							{
								type: "text",
								name: "surname",
								label: <Label number={55} />,
								value: user.surname,
								required: true,
							},
							{
								type: "tel",
								name: "mobile",
								label: <Label number={56} />,
								value: { area_code: user.area_code, mobile: user.mobile },
								required: true,
							},
							{
								type: "date",
								name: "birth_date",
								label: <Label number={130} />,
								value: user.birth_date,
								required: settings.customers?.birth_date_required == 1,
								disabled: settings.customers?.show_birth_date_in_registration != 1,
							},
							{
								type: "select",
								name: "customer_lang",
								label: <Label number={184} />,
								value: user.customer_lang,
								required: true,
								options: [
									{ name: "Italiano", value: "it" },
									{ name: "Chinese", value: "zh" },
									{ name: "English", value: "en" },
									{ name: "Francese", value: "fr" },
								],
							},
						]}
						submitButtonLabel={<Label number={43} />}
					/>
					<button className="button" onClick={() => navigate("/new_password")}>
						<Label number={143} />
					</button>
				</div>
				<div className="box">
					<Marketing
						marketing={marketing}
						setMarketing={setMarketing}
						newsletter={newsletter}
						setNewsletter={setNewsletter}
						checkUser={false}
					/>
					<button
						className="button button-primary"
						onClick={() =>
							saveUser({
								newsletter: newsletter ? 1 : 0,
								marketing: marketing ? 1 : 0,
							})
						}
					>
						<Label number={43} />
					</button>
				</div>
				{hasDelivery == 1 && addressesDelivery && (
					<div className="box">
						<h1>
							<Label number={44} />
						</h1>
						{addressesDelivery.map((address, i) => (
							<div key={i} className="address-container">
								<div>
									<div>{address.address + " " + address.number}</div>
									<div>
										{address.zipcode +
											" " +
											address.city +
											" (" +
											address.province +
											")"}
									</div>
									<div>{address.doorphone}</div>
								</div>
								<div>
									<button
										className="button button-primary"
										onClick={() => deleteAddressesDelivery(address.id)}
									>
										<Label number={48} />
									</button>
								</div>
							</div>
						))}
					</div>
				)}
				{settings.orders.invoice == 1 && addressesBilling && (
					<div className="box">
						<h1>
							<Label number={45} />
						</h1>
						{addressesBilling.map((address, i) => (
							<div key={i} className="address-container">
								<div>
									<div>
										<b>{address.company}</b>
									</div>
									<div>SDI: {address.sdi}</div>
									<div>
										<Label number={189} />: {address.vat}
									</div>
									<div>{address.address + " " + address.number}</div>
									<div>
										{address.zipcode +
											" " +
											address.city +
											" (" +
											address.province +
											")"}
									</div>
								</div>
								<div className="buttons">
									<Link
										to={"/billing_address/" + address.id}
										state={address}
										className="button button-primary"
									>
										<Label number={49} />
									</Link>
									<button
										onClick={() => deleteAddressesBilling(address.id)}
										className="button button-primary"
									>
										<Label number={48} />
									</button>
								</div>
							</div>
						))}
						<Link to="/billing_address" className="button button-primary">
							<Label number={18} />
						</Link>
					</div>
				)}
				{settings.mandant.affiliated_companies == 1 && (
					<div className="box">
						<h1>
							<Label number={46} />
						</h1>
						{!user.company_id ? (
							<Form
								onSubmit={checkAffiliationCode}
								fields={[
									{
										type: "text",
										name: "code",
										label: <Label number={196} />,
										value: "",
										required: true,
									},
								]}
								submitButtonLabel={<Label number={197} />}
							/>
						) : (
							<div>
								<div>
									<b>{user.company.name}</b>
								</div>
								<div>
									{user.company.address + ", " + user.company.number}
									<br />
									{user.company.zipcode + " " + user.company.city}
								</div>
								{user.company.discount > 0 && (
									<div>
										<Label number={198} />: {user.company.discount}%
									</div>
								)}
								{user.company.free_delivery_price == 1 && (
									<div>
										<Label number={207} />: <Label number={39} />
									</div>
								)}
								{user.company_type == "code" && (
									<button
										className="button button-primary"
										onClick={() => cancelAffiliation()}
									>
										<Label number={199} />
									</button>
								)}
							</div>
						)}
					</div>
				)}
				<div className="box">
					<h1>
						<Label number={47} />
					</h1>
					<button className="button button-primary" onClick={deleteUser}>
						<Label number={48} />
					</button>
				</div>
			</div>
		</div>
	);
}

export default Userboard;
